<template>
  <div>
    <navBar/>
    <Spinner/>
      <div id="layoutAuthentication">
            <div id="layoutAuthentication_content">
                <main>
                    <div class="container-xl px-4">
                        <div class="row justify-content-center">
                            <div class="col-xl-5 col-lg-6 col-md-8 col-sm-11">
                                <div class="card my-5">
                                    <div class="card-body p-5 text-center">
                                        <div class="h3 fw-light mb-3 titulo-login">Seleccione empresa a operar</div>
                                    </div>
                                    <hr class="my-0" />
                                    <div class="card-body p-5">
                                        <form>
                                            <div class="mb-4 text-left">
                                                <label class="text-gray-600 small " for="emailExample">Empresa</label>
                                                <select class="form-control form-control-solid" id="empresaUser">
                                                    <option value="00"> Seleccioná una opción </option>
                                                    <option v-for="(empresa,index) in this.empresas" :value="empresa.id"> {{empresa.razon_social}} </option>
                                                </select>
                                            </div>
                                            <div class="d-flex align-items-center justify-content-center mb-0 mt-4 pt-4">
                                                <a class="btn btn-primary color-btn" @click="redirectHome">Ingresar</a>
                                            </div>
                                        </form>
                                    </div>
                               
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
   
        </div>
  </div>
</template>


<style>
/*Color del fondo, sobreescribiendo variable de del tema de boostrap SB ADMIN*/
:root {
    --bs-body-bg: #F5F5F5;
}

.titulo-login{
    font-size: 30px;
    color: rgb(0, 47, 135);
}
.color-btn{
    background: #002f87 !important;
    border:none;
}
.btn-link{
    color:#0061f2;
    cursor: pointer;
}
</style>

<script>
import navBar from "../components/landing/navBar";
import router from '@/router/index.js' // Vue router instance
import spinner from '../components/spinner'
import Vue from 'vue'
import Spinner from "../components/spinner"

export default {
  name: "Home",
  components: {
    navBar,Spinner
  },
  mounted: function () {
    this.$store.commit("SET_BLOQUEADO", false);
  },
  methods: {
    redirectHome(){
        if(document.getElementById("empresaUser").value == "00"){
            Vue.swal("Portal de Proveedoras/es", "Debe seleccionar una empresa", "info");
        }else{
            this.$store.dispatch("login/setEmpresaByUser",document.getElementById("empresaUser").value).then(()=>{
                return this.$store.dispatch("mensajes/getMensajesByUsuario",1)
            });
        }
    }
  },
  computed: {
    user: {
        get() {
          return this.$store.state.login.user;
        }
    },
    empresas: {
        get() {
          return this.$store.state.login.empresas_by_user;
        }
    },
  },
};
</script>
